import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import axios from 'axios'
import moment from 'moment/moment'
import numeral from 'numeral'
import './registerServiceWorker'
import JsonExcel from "vue-json-excel"

Vue.component("downloadExcel", JsonExcel)

Vue.config.productionTip = false

Vue.prototype.$http = axios
// Vue.prototype.$api = 'http://127.0.0.1:8000/api'
// Vue.prototype.$api = 'http://192.168.100.7:8000/api'
Vue.prototype.$api = 'https://test.be.tokowelly.biz.id/api'
// Vue.prototype.$api = 'https://be.tokowelly.biz.id/api'



new Vue({
  vuetify,
  router,
  axios,
  moment,
  numeral,
  render: h => h(App),
}).$mount('#app')
