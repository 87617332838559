import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

function importComponent(path){
    return () => import(`./components/${path}.vue`)
}

const router = new VueRouter({
    mode: 'history',
    routes: [
    {
        path:'/login',
        name: 'Login',
        meta: {title:'Login'},
        component: importComponent('Login')
    },
    {
        path:'/',
        name: 'NavigationLayout',
        component: importComponent('NavigationLayout'),
        children: [
            {
                path: '/',
                name: 'Dashboard',
                meta:{title:'Dashboard'},
                component: importComponent('Dashboard')
            },
            {
                path: '/product',
                name: 'Product',
                meta:{title:'Product'},
                component: importComponent('Product')
            },
            {
                path: '/brand',
                name: 'Brand',
                meta:{title:'Brand'},
                component: importComponent('Brand')
            },
            {
                path: '/customer',
                name: 'Customer',
                meta:{title:'Customer'},
                component: importComponent('Customer')
            },
            {
                path: '/supplier',
                name: 'Supplier',
                meta:{title:'Supplier'},
                component: importComponent('Supplier')
            },
            {
                path: '/trip',
                name: 'Trip',
                meta:{title:'Trip'},
                component: importComponent('Trip')
            },
            {
                path: '/purchase-return',
                name: 'Purchase Return',
                meta:{title:'Purchase Return'},
                component: importComponent('PurchaseReturn')
            },
            {
                path: '/sales-return',
                name: 'Sales Return',
                meta:{title:'Sales Return'},
                component: importComponent('SalesReturn')
            },
            {
                path: '/purchase',
                name: 'Purchase',
                meta:{title:'Purchase'},
                component: importComponent('Purchase')
            },
            {
                path: '/transaction',
                name: 'Transaction',
                meta:{title:'Transaction'},
                component: importComponent('Transaction')
            },
            {
                path: '/active-trip',
                name: 'Active Trip',
                meta:{title:'Active Trip'},
                component: importComponent('ActiveTripOptimized')
            },
            {
                path: '/report',
                name: 'Report',
                meta:{title:'Report'},
                component: importComponent('Report')
            },
            {
                path: '/user',
                name: 'User',
                meta:{title:'User'},
                component: importComponent('User')
            },
        ]
    }
    ]
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Dashboard' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Login' && localStorage.getItem('token')!==null) next({ name: 'Dashboard'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Product' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Brand' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Customer' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Supplier' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Trip' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Purchase Return' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})
router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Sales Return' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Purchase' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Transaction' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Active Trip' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'Report' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})

router.beforeEach((to, from, next)=>{
    document.title = to.meta.title
    if(to.name === 'User' && localStorage.getItem('token')===null) next({ name: 'Login'})
    else next()
})
// router.beforeEach((to, from, next)=>{
//     document.title = to.meta.title
//     if(to.name === 'Dashboard' && localStorage.getItem('token')!==null) next({name: localStorage.getItem('role')})
//     else next()
// })

export default router